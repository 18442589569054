<template>
  <div>
    <TransitionComponent>
      <div
        v-if="!profileData"
        class="bg-gray-800 absolute w-screen h-screen flex items-center justify-center justify-items-center"
      >
        <svg
          class="animate-spin -ml-1 mr-3 h-10 w-10 text-white"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle
            class="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            stroke-width="4"
          ></circle>
          <path
            class="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          ></path>
        </svg>
      </div>
    </TransitionComponent>
    <TransitionComponent>
      <Summary v-if="profileData" :data="profileData" />
    </TransitionComponent>
  </div>
</template>

<script>
import Summary from '@/components/summary/Summary'
import TransitionComponent from '@/components/common/transition/Transition.vue'
import { computed, onMounted } from 'vue'
import { useStore } from 'vuex'
import { FETCH_DATA_BY_EMAIL } from '@/store/modules/profile/actions.type'
import { PROFILE_STORE_NAME } from '@/store//modules/profile/index.js'

export default {
  components: {
    Summary,
    TransitionComponent
  },
  props: {
    email: {
      type: String,
      required: true,
      default: null
    }
  },
  setup(props) {
    const store = useStore()
    onMounted(() => {
      store.dispatch(
        `${PROFILE_STORE_NAME}/${FETCH_DATA_BY_EMAIL}`,
        props.email
      )
    })
    return {
      profileData: computed(() => {
        console.log(store.getters[`${PROFILE_STORE_NAME}/profileData`])

        return store.getters[`${PROFILE_STORE_NAME}/profileData`]
      })
    }
  }
}
</script>

<style></style>
